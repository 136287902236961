import { Modal, ModalBody, ModalHeader } from "reactstrap";

const DownloadCustomerLedger = ({ openModal, toggle, toggleDownloadType }) => {
  return (<Modal isOpen={openModal} toggle={toggle} centered>
    <ModalHeader toggle={toggle} >
      Download Customer Ledger
    </ModalHeader>
    <ModalBody>
      <div className="d-flex  justify-content-center gap-4">
        <button className="btn btn-primary"
          onClick={() => toggleDownloadType("excel")}
        >Download as Excel</button>
        <button
          onClick={() => toggleDownloadType("pdf")}
          className="btn btn-primary"
        >Download as Pdf</button>
      </div>
    </ModalBody>
  </Modal>
  )
}
export default DownloadCustomerLedger;